<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->

    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <div style="display: flex; width: 100%">
            
            <el-input
              v-model="searchtitle"
              placeholder="请输入企业名称"
              style="width:300px"
              clearable
            ></el-input>
            <el-button
              type="primary"
              slot="append"
              size="small"
              @click="searchgetcompany"
              style="margin-left: 20px"
              >查询</el-button
            >
          </div>
        </el-col>
        <el-col :span="12" align="right">
          
          <el-button class="el-icon-edit" type="primary"   size="small"   @click="addSchoolInfo"
            >添加企业信息</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->
   
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
      
        <el-table-column
          align="center"
          prop="companyName"
          label="企业名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="areaName"
          label="所在地区"
          :show-overflow-tooltip="true"
        />

      
        <el-table-column
          align="center"
          prop="address"
          label="企业地址"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="username"
          label="管理员姓名"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="tel"
          label="联系电话"
          :show-overflow-tooltip="true"
        >
        </el-table-column>


        <el-table-column
          align="center"
          prop="account"
          label="管理员账号"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
      
        <el-table-column align="center" label="操作" width="251px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="goorder(scope.row)"
            >
              合同管理
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="gouser(scope.row)"
            >
              企业人员
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="updateSchoolInfo(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="delcompany(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getcompany"
      />
   
  </div>
</template>

<script>
import {
  getcompany,
  delcompany,

} from "../../request/http";

export default {
  data() {
    return {
      dialog: false,
    
      title: "", //弹出框标题
      loading: true, //加载动画
      showDialog: false, //显示隐藏
      list: "", // 列表
      total: 0, //总条数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
     
      searchtitle: "", //搜索名称
     
    };
  },
  created() {
    this.getcompany();

  },
  methods: {
 
    gouser(row){
     
      let companyId = row.companyId;
      this.$router.push({
        path: "/companyuser",
        query: { companyId },
      });
    },
    goorder(row){
      let companyId = row.companyId;
      this.$router.push({
        path: "/contract",
        query: { companyId },
      });
    },
 
   
  
    //获取列表
    async getcompany() {
      let { data } = await getcompany(this.queryParams);
      
      this.list = data.data.records;
      this.total = data.data.total;
      console.log(this.list);
      this.loading = false;
    },
    //查询
    async searchgetcompany() {
      this.queryParams = {
        page: 1,
        pageSize: 10,
        companyName: this.searchtitle,
      };

      let { data } = await getcompany(this.queryParams);
      
      this.list = data.data.records;
      this.total = data.data.total;
      
    },
   

    // 打开添加的窗口
    addSchoolInfo() {
        this.editOradd = 0;
      let editOradd = this.editOradd;
      this.$router.push({
        path: "/addcompany",
        query: { editOradd },
      });
    },
    // 打开编辑类别的窗口
    updateSchoolInfo(val) {
        this.editOradd = 1;
      let editOradd = this.editOradd;
      let obj = val;
      this.$router.push({
        path: "/addcompany",
        query: { editOradd, obj },
      });
    
    },

 

   
    //删除信息
    async delcompany(row) {
      try {
        await this.$confirm("确定要删除改企业吗");
        await delcompany(row.companyId);
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.pageNum =
          this.queryParams.pageNum > totalPage ? totalPage : this.queryParams.pageNum;
        this.queryParams.pageNum =
          this.queryParams.pageNum < 1 ? 1 : this.queryParams.pageNum;
        this.getcompany();

        this.$message.success("删除成功");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>